.header {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 24px 0;
}

.wrapper--left {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @include media-query(lg) {
        padding-right: 0;
        max-width: 800px;
    }
}

.wrapper--right {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    @include media-query(lg) {
        padding-left: 0;
        max-width: 800px;
    }
}

.wrapper--small {
    @include media-query(lg) {
        max-width: 600px;
    }
}

.logo-wrapper {
    display: block;
    margin-right: 48px;

    @include media-query(lg) {
        margin-right: 96px;
    }
}

.logo {
    display: inline-block;
    vertical-align: middle;
}

.logo__image {
    height: 48px;

    @include media-query(lg) {
        height: 96px;
    }
}

.nav {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
}

.nav-button {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: $color_teal;

    @include media-query(lg) {
        width: 96px;
        height: 96px;
    }
}

.nav-button-icon {
    position: relative;
    width: 28px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;

    .is-active & {
        background-color: transparent;
    }

    @include media-query(lg) {
        width: 56px;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        border-radius: 1px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        .is-active & {
            width: 28px;
        }

        @include media-query(lg) {
            width: 40px;

            .is-active & {
                width: 56px;
            }
        }
    }

    &:before {
        top: -8px;

        .is-active & {
            top: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
        }

        @include media-query(lg) {
            top: -16px;
        }
    }

    &:after {
        bottom: -8px;

        .is-active & {
            bottom: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }

        @include media-query(lg) {
            bottom: -16px;
        }
    }
}

.nav-button-label {
    @include hidden();
}

.nav-panel {
    @include hidden();
    opacity: 0;

    &.is-active {
        @include visible();
        position: fixed;
        z-index: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: $color_teal;
        opacity: 1;
        overflow: auto;
        display: flex;
    }

    ul {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;

        @include media-query(lg) {
            padding: 96px;
        }
    }

    a:not([class]) {
        font-size: 2.4rem;
        font-weight: bold;
        color: #fff;

        @include media-query(lg) {
            font-size: 4.8rem;
        }
    }

    .button {
        margin: 1.2rem 0 0;
        font-size: 2rem;

        @include media-query(lg) {
            margin: 2.4rem 0 0;
            padding: 1.6rem 3rem;
            font-size: 3rem;
        }
    }
}

