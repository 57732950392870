.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 -#{$grid-gutter/2};
  }
  
  .grid-item {
    @for $i from 1 through $grid-columns {
      &-#{$i} {
        width: #{100%/$grid-columns*$i};
        padding: 0 #{$grid-gutter/2};
      }
    }
    
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-query($breakpoint) {
        @for $i from 1 through $grid-columns {
          &-#{$i}--#{$breakpoint} {
            width: #{100%/$grid-columns*$i};
            padding: 0 #{$grid-gutter/2};
          }
        }
      }
    }
  }
  