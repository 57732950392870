.departments {
    width: 100%;
    position: relative;
    z-index: 0;
    display: grid;
    grid-gap: 0px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        "top"
        "bottom";

    @include media-query(lg) {
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        grid-gap: 48px;
        padding-bottom: 4.8rem;

        // &:after {
        //     content: '';
        //     display: block;
        //     width: 1px;
        //     height: 100%;
        //     background-color: $color_marine;
        //     position: absolute;
        //     top: 0;
        //     left: 50%;
        // }
    }
}

.departments__body {
    grid-area: top;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    padding: 3.2rem 0;

    h2 {
        @extend h1;
    }

    @include media-query(lg) {
        grid-area: left;
        text-align: right;
        padding: 4.8rem 0;
    }
}

.departments__slideshow {
    width: 100%;
    overflow: hidden;
    grid-area: bottom;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 2;

    @include media-query(lg) {
        grid-area: right;
        padding-left: 0;
        padding-right: 0;
    }
}

.departments__slideshow__slides {
    margin: 0 -12px;

    @include media-query(lg) {
        margin: 0 -24px;
    }
}

.departments__slideshow__slide {
    padding: 0 12px;

    @include media-query(lg) {
        padding: 0 24px;
    }
}

.departments__controls {
    padding-top: 1.6rem;
    display: flex;

    @include media-query(lg) {
        padding-top: 2.4rem;
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid $color_marine;

        &+& {
            margin-left: 8px;
        }

        &__icon {
            width: 16px;
            height: 16px;
            fill: $color_marine;
        }
    }
}

.department__image {
    opacity: 0.5;

    .slick-current & {
        opacity: 1;
    }
}

.department__body {
    visibility: hidden;
    text-align: center;

    .slick-current & {
        visibility: visible;
    }
}

.department__name {
    font-family: "Poppins", sans-serif;
    font-size: 2.2rem;
    line-height: 1.25;
    color: $color_teal;
    margin-bottom: 0 !important;
}

.teammembers__grid--new {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 4.8rem 3.2rem;
}

.teammember__body--new {
    display: grid;
    gap: .8rem;
}