.phil__slideshow {
    position: relative;

    &__wrapper {
        width: 100%;
    }
}

.phil__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-query(lg) {
        padding-top: 6.4rem;
    }

    &__control {
        position: absolute;
        z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid $color_marine;
        background-color: #fff;

        &.is-hidden {
            display: none;
        }

        &--next {
            left: 24px;
        }

        &--prev {
            right: 24px;
        }

        @include media-query(lg) {
            width: 48px;
            height: 48px;
            border-radius: 24px;
        }

        &__icon {
            width: 12px;
            height: 12px;
            fill: $color_marine;

            @include media-query(lg) {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.phil__slogan {
    font-size: 2.4rem;

    @include media-query(lg) {
        font-size: 4rem;
    }

    &__part {
        &.is-hidden {
            display: none;
        }
    }
}

.phil__body {
    position: relative;
    padding-left: 6.4rem;

    @include media-query(lg) {
        padding-left: 9.6rem;
    }

    .phil__body__number {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 3.6rem;
        font-weight: bold;
        line-height: 1;
        color: $color_teal;

        @include media-query(lg) {
            font-size: 4.8rem;
        }
    }
}

.phil__dots {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.4rem;

    ul {
        display: flex;
        align-items: center;
    }

    li {
        padding: 4px;

        &.slick-active button {
            position: relative;

            &:before {
                content: "";
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: $color_marine;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    button {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        display: block;
        background-color: #fff;
        border: 2px solid $color_marine;
        overflow: hidden;
        text-indent: -999rem;

        &:hover {
            cursor: pointer;
        }
    }
}
