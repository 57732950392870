.flow {
    padding: 1.6rem;

    @include media-query(lg) {
        min-height: 75vh;
    }

    &__question {
        display: block;
        font-size: 1.6rem;
        margin-bottom: .8em;
    }

    &__answer {
        transition: opacity .25s ease;

        &.is-disabled {
            opacity: .5;
        }

        & + .flow__answer {
            margin-top: .8rem;
        }

        input {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;

            &:checked {
                & + label {
                    background-color: rgba($color_teal, .075);
                    border-color: $color_teal;
                }
            }
        }

        label {
            display: block;
            font-weight: 500;
            line-height: 1.33;
            border: 2px solid $color_lightgrey;
            padding: 1.2rem 1.4rem;
            border-radius: .5rem;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__reply {
        display: flex;
        font-size: 1.5rem;
        font-weight: 500;
        background-color: rgba($color_leafs, .075);
        padding: 1.6rem;
        border-radius: .4rem;
    }

    &__reply-icon {
        flex: 0 0 auto;
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        fill: $color_leafs;
        margin-top: .2rem;
        margin-right: 1.6rem;
    }
}

.flow__list-item {
    width: 100%;
    max-width: 72rem;
    margin: 1.6rem auto 0;

    &:first-child {
        margin-top: 0;
    }

    &.is-hidden {
        display: none;
    }
}