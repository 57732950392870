.cookiebanner {
    position: fixed;
    z-index: 99;
    bottom: 24px;
    left: 24px;
    width: 640px;
    height: auto;
    max-width: calc(100% - 48px);
    clip: auto;
    clip-path: none;
    overflow: visible;
    white-space: normal;

    &.is-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background-image: radial-gradient(circle at bottom left, rgba(#000, .1), rgba(#000, 0) 50%);
        background-size: cover;
        background-repeat: no-repeat;
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;
    }
}

.cookiebanner__body {
    position: relative;
    z-index: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 1s ease, transform 1s ease;

    .cookiebanner.is-hidden & {
        opacity: 0;
        transform: translate3d(0, 24px, 0);
    }

    &.is-hidden {
        display: none;
    }
}

.cookiebanner__options {
    margin-top: 16px;
}

.cookiebanner__actions {
    margin-top: 24px;

    ul {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        list-style: none;
    }

    li {
        & + li {
            margin-left: 16px;
        }
    }
}

.switch__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:checked + .switch__field{
        .switch__symbol {
            background-color: $color_teal;

            &:before {
                transform: translateX(20px);
            }
        }
    }
}

.switch__field {
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.switch__symbol {
    position: relative;
    display: block;
    width: 48px;
    height: 28px;
    background-color: #eee;
    border-radius: 14px;
    margin-right: 16px;
    transition: background-color .2s ease;

    &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        left: 4px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 4px rgba(#333, .1);
        transition: transform .2s ease;
    }
}

.switch__label {
    font-weight: 500;
    line-height: 1.5;

    small {
        display: block;
        font-weight: normal;
        color: #999;
    }
}
