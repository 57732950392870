body {
    background-color: #fff;

    &.is-fixed {
        overflow: hidden;
    }
}

h1,
.h1 {
    font-family: "Prata", serif;
    font-size: 3.6rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 6.4rem;
    }
}

h2,
.h2 {
    font-family: "Marcellus", serif;
    font-size: 2.4rem;

    @include media-query(lg) {
        font-size: 2.8rem;
    }
}

h3,
.h3 {
    font-family: "Marcellus", serif;
    font-size: 2rem;

    @include media-query(lg) {
        font-size: 2rem;
    }
}

h4,
.h4 {
    font-family: "Marcellus", serif;
    font-size: 1.6rem;

    @include media-query(lg) {
        font-size: 1.8rem;
    }
}

h5,
.h5 {
    font-family: "Marcellus", serif;
    font-size: 1.4rem;

    @include media-query(lg) {
        font-size: 1.6rem;
    }
}

h6,
.h6 {
    font-family: "Marcellus", serif;
    font-size: 1.2rem;

    @include media-query(lg) {
        font-size: 1.4rem;
    }
}

.text {
    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }
    
    &--center {
        text-align: center;
    }
}

.heading-label {
    display: inline-block;
    padding: .5rem 2rem;
    background-color: $color_teal;
    color: #fff;
}

blockquote {
    @extend h2;

    &:before,
    &:after {
        content: '"';
        
    }
}