.scan {
    &__pagetitle {
        margin-bottom: 6.4rem;
    }
}

.question {
    & + .question {
        margin-top: 1.6rem;
    }
}

.suggestion {
    margin-top: 3.2rem;
    padding-left: 7.6rem;
}

.widget--timeline {
    max-width: 20rem;
    margin: 0 auto;
}