.jobs {
    &__grid {
        &--recent {
            display: grid;
            grid-gap: 3.2rem;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            width: 100%;
        }
    }
}

.job {
    &--small {
        display: flex;
        flex-direction: column;
        padding: 2.4rem;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 4px 16px rgba($color_marine, 0.2);

        @include media-query(sm) {
            flex-direction: row;
            align-items: center;
        }
    }
}
