.card {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.4rem 1.6rem rgba(#000, 0.1);
    padding: 2.4rem;

    @include media-query(lg) {
        padding: 3.6rem;
    }

    &__title {
        margin-bottom: 2rem;
    }
}

.card__header {
    display: flex;
}

.card__indicator {
    margin-left: auto;
    padding-left: 1.6rem;
    font-weight: 500;
    color: $color_mouse;
}