.spt {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding-top: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding-top: map-get($spacings, $spacing);
                }
            }
        }
    }
}

.spb {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding-bottom: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding-bottom: map-get($spacings, $spacing);
                }
            }
        }
    }
}

.spl {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding-left: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding-left: map-get($spacings, $spacing);
                }
            }
        }
    }
}

.spr {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding-right: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding-right: map-get($spacings, $spacing);
                }
            }
        }
    }
}

.sp {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding: map-get($spacings, $spacing);
                }
            }
        }
    }
}

.spx {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding-left: map-get($spacings, $spacing);
            padding-right: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding-left: map-get($spacings, $spacing);
                    padding-right: map-get($spacings, $spacing);
                }
            }
        }
    }
}

.spy {
    @each $spacing in map-keys($spacings) {
        &--#{$spacing} {
            padding-top: map-get($spacings, $spacing);
            padding-bottom: map-get($spacings, $spacing);
        }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-query($breakpoint) {
            @each $spacing in map-keys($spacings) {
                &--#{$spacing}--#{$breakpoint} {
                    padding-top: map-get($spacings, $spacing);
                    padding-bottom: map-get($spacings, $spacing);
                }
            }
        }
    }
}