html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 10px; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  width: 100%;
  min-height: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #232346; }

body,
h1,
.experts__body h2,
.experts__body blockquote,
.departments__body h2,
.departments__body blockquote,
.organigram__item .count,
h2,
blockquote,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal; }


h1,
.experts__body h2,
.experts__body blockquote,
.departments__body h2,
.departments__body blockquote,
.organigram__item .count,
h2,
blockquote,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  color: inherit; }

ol,
ul {
  list-style: none; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none;
  color: inherit; }

body {
  background-color: #fff; }
  body.is-fixed {
    overflow: hidden; }


h1,
.experts__body h2,
.experts__body blockquote,
.departments__body h2,
.departments__body blockquote,
.organigram__item .count,
.h1 {
  font-family: "Prata", serif;
  font-size: 3.6rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    
    h1,
    .experts__body h2,
    .experts__body blockquote,
    .departments__body h2,
    .departments__body blockquote,
    .organigram__item .count,
    .h1 {
      font-size: 6.4rem; } }


h2,
blockquote,
.h2 {
  font-family: "Marcellus", serif;
  font-size: 2.4rem; }
  @media (min-width: 1024px) {
    
    h2,
    blockquote,
    .h2 {
      font-size: 2.8rem; } }

h3,
.h3 {
  font-family: "Marcellus", serif;
  font-size: 2rem; }
  @media (min-width: 1024px) {
    h3,
    .h3 {
      font-size: 2rem; } }

h4,
.h4 {
  font-family: "Marcellus", serif;
  font-size: 1.6rem; }
  @media (min-width: 1024px) {
    h4,
    .h4 {
      font-size: 1.8rem; } }

h5,
.h5 {
  font-family: "Marcellus", serif;
  font-size: 1.4rem; }
  @media (min-width: 1024px) {
    h5,
    .h5 {
      font-size: 1.6rem; } }

h6,
.h6 {
  font-family: "Marcellus", serif;
  font-size: 1.2rem; }
  @media (min-width: 1024px) {
    h6,
    .h6 {
      font-size: 1.4rem; } }

.text--left {
  text-align: left; }

.text--right {
  text-align: right; }

.text--center {
  text-align: center; }

.heading-label {
  display: inline-block;
  padding: .5rem 2rem;
  background-color: #168C7F;
  color: #fff; }

blockquote:before, blockquote:after {
  content: '"'; }

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 -24px; }

.grid-item-1 {
  width: 8.33333%;
  padding: 0 24px; }

.grid-item-2 {
  width: 16.66667%;
  padding: 0 24px; }

.grid-item-3 {
  width: 25%;
  padding: 0 24px; }

.grid-item-4 {
  width: 33.33333%;
  padding: 0 24px; }

.grid-item-5 {
  width: 41.66667%;
  padding: 0 24px; }

.grid-item-6 {
  width: 50%;
  padding: 0 24px; }

.grid-item-7 {
  width: 58.33333%;
  padding: 0 24px; }

.grid-item-8 {
  width: 66.66667%;
  padding: 0 24px; }

.grid-item-9 {
  width: 75%;
  padding: 0 24px; }

.grid-item-10 {
  width: 83.33333%;
  padding: 0 24px; }

.grid-item-11 {
  width: 91.66667%;
  padding: 0 24px; }

.grid-item-12 {
  width: 100%;
  padding: 0 24px; }

@media (min-width: 400px) {
  .grid-item-1--xs {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--xs {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--xs {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--xs {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--xs {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--xs {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--xs {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--xs {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--xs {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--xs {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--xs {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--xs {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 640px) {
  .grid-item-1--sm {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--sm {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--sm {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--sm {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--sm {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--sm {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--sm {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--sm {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--sm {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--sm {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--sm {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--sm {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 768px) {
  .grid-item-1--md {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--md {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--md {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--md {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--md {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--md {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--md {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--md {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--md {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--md {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--md {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--md {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 1024px) {
  .grid-item-1--lg {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--lg {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--lg {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--lg {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--lg {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--lg {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--lg {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--lg {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--lg {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--lg {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--lg {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--lg {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 1280px) {
  .grid-item-1--xl {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--xl {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--xl {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--xl {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--xl {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--xl {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--xl {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--xl {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--xl {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--xl {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--xl {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--xl {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 1600px) {
  .grid-item-1--xxl {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--xxl {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--xxl {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--xxl {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--xxl {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--xxl {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--xxl {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--xxl {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--xxl {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--xxl {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--xxl {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--xxl {
    width: 100%;
    padding: 0 24px; } }

.spt--none {
  padding-top: 0; }

.spt--xs {
  padding-top: 0.8rem; }

.spt--sm {
  padding-top: 1.6rem; }

.spt--md {
  padding-top: 2.4rem; }

.spt--lg {
  padding-top: 3.2rem; }

.spt--xl {
  padding-top: 4.8rem; }

.spt--xxl {
  padding-top: 6.4rem; }

.spt--xxxl {
  padding-top: 8rem; }

@media (min-width: 400px) {
  .spt--none--xs {
    padding-top: 0; }
  .spt--xs--xs {
    padding-top: 0.8rem; }
  .spt--sm--xs {
    padding-top: 1.6rem; }
  .spt--md--xs {
    padding-top: 2.4rem; }
  .spt--lg--xs {
    padding-top: 3.2rem; }
  .spt--xl--xs {
    padding-top: 4.8rem; }
  .spt--xxl--xs {
    padding-top: 6.4rem; }
  .spt--xxxl--xs {
    padding-top: 8rem; } }

@media (min-width: 640px) {
  .spt--none--sm {
    padding-top: 0; }
  .spt--xs--sm {
    padding-top: 0.8rem; }
  .spt--sm--sm {
    padding-top: 1.6rem; }
  .spt--md--sm {
    padding-top: 2.4rem; }
  .spt--lg--sm {
    padding-top: 3.2rem; }
  .spt--xl--sm {
    padding-top: 4.8rem; }
  .spt--xxl--sm {
    padding-top: 6.4rem; }
  .spt--xxxl--sm {
    padding-top: 8rem; } }

@media (min-width: 768px) {
  .spt--none--md {
    padding-top: 0; }
  .spt--xs--md {
    padding-top: 0.8rem; }
  .spt--sm--md {
    padding-top: 1.6rem; }
  .spt--md--md {
    padding-top: 2.4rem; }
  .spt--lg--md {
    padding-top: 3.2rem; }
  .spt--xl--md {
    padding-top: 4.8rem; }
  .spt--xxl--md {
    padding-top: 6.4rem; }
  .spt--xxxl--md {
    padding-top: 8rem; } }

@media (min-width: 1024px) {
  .spt--none--lg {
    padding-top: 0; }
  .spt--xs--lg {
    padding-top: 0.8rem; }
  .spt--sm--lg {
    padding-top: 1.6rem; }
  .spt--md--lg {
    padding-top: 2.4rem; }
  .spt--lg--lg {
    padding-top: 3.2rem; }
  .spt--xl--lg {
    padding-top: 4.8rem; }
  .spt--xxl--lg {
    padding-top: 6.4rem; }
  .spt--xxxl--lg {
    padding-top: 8rem; } }

@media (min-width: 1280px) {
  .spt--none--xl {
    padding-top: 0; }
  .spt--xs--xl {
    padding-top: 0.8rem; }
  .spt--sm--xl {
    padding-top: 1.6rem; }
  .spt--md--xl {
    padding-top: 2.4rem; }
  .spt--lg--xl {
    padding-top: 3.2rem; }
  .spt--xl--xl {
    padding-top: 4.8rem; }
  .spt--xxl--xl {
    padding-top: 6.4rem; }
  .spt--xxxl--xl {
    padding-top: 8rem; } }

@media (min-width: 1600px) {
  .spt--none--xxl {
    padding-top: 0; }
  .spt--xs--xxl {
    padding-top: 0.8rem; }
  .spt--sm--xxl {
    padding-top: 1.6rem; }
  .spt--md--xxl {
    padding-top: 2.4rem; }
  .spt--lg--xxl {
    padding-top: 3.2rem; }
  .spt--xl--xxl {
    padding-top: 4.8rem; }
  .spt--xxl--xxl {
    padding-top: 6.4rem; }
  .spt--xxxl--xxl {
    padding-top: 8rem; } }

.spb--none {
  padding-bottom: 0; }

.spb--xs {
  padding-bottom: 0.8rem; }

.spb--sm {
  padding-bottom: 1.6rem; }

.spb--md {
  padding-bottom: 2.4rem; }

.spb--lg {
  padding-bottom: 3.2rem; }

.spb--xl {
  padding-bottom: 4.8rem; }

.spb--xxl {
  padding-bottom: 6.4rem; }

.spb--xxxl {
  padding-bottom: 8rem; }

@media (min-width: 400px) {
  .spb--none--xs {
    padding-bottom: 0; }
  .spb--xs--xs {
    padding-bottom: 0.8rem; }
  .spb--sm--xs {
    padding-bottom: 1.6rem; }
  .spb--md--xs {
    padding-bottom: 2.4rem; }
  .spb--lg--xs {
    padding-bottom: 3.2rem; }
  .spb--xl--xs {
    padding-bottom: 4.8rem; }
  .spb--xxl--xs {
    padding-bottom: 6.4rem; }
  .spb--xxxl--xs {
    padding-bottom: 8rem; } }

@media (min-width: 640px) {
  .spb--none--sm {
    padding-bottom: 0; }
  .spb--xs--sm {
    padding-bottom: 0.8rem; }
  .spb--sm--sm {
    padding-bottom: 1.6rem; }
  .spb--md--sm {
    padding-bottom: 2.4rem; }
  .spb--lg--sm {
    padding-bottom: 3.2rem; }
  .spb--xl--sm {
    padding-bottom: 4.8rem; }
  .spb--xxl--sm {
    padding-bottom: 6.4rem; }
  .spb--xxxl--sm {
    padding-bottom: 8rem; } }

@media (min-width: 768px) {
  .spb--none--md {
    padding-bottom: 0; }
  .spb--xs--md {
    padding-bottom: 0.8rem; }
  .spb--sm--md {
    padding-bottom: 1.6rem; }
  .spb--md--md {
    padding-bottom: 2.4rem; }
  .spb--lg--md {
    padding-bottom: 3.2rem; }
  .spb--xl--md {
    padding-bottom: 4.8rem; }
  .spb--xxl--md {
    padding-bottom: 6.4rem; }
  .spb--xxxl--md {
    padding-bottom: 8rem; } }

@media (min-width: 1024px) {
  .spb--none--lg {
    padding-bottom: 0; }
  .spb--xs--lg {
    padding-bottom: 0.8rem; }
  .spb--sm--lg {
    padding-bottom: 1.6rem; }
  .spb--md--lg {
    padding-bottom: 2.4rem; }
  .spb--lg--lg {
    padding-bottom: 3.2rem; }
  .spb--xl--lg {
    padding-bottom: 4.8rem; }
  .spb--xxl--lg {
    padding-bottom: 6.4rem; }
  .spb--xxxl--lg {
    padding-bottom: 8rem; } }

@media (min-width: 1280px) {
  .spb--none--xl {
    padding-bottom: 0; }
  .spb--xs--xl {
    padding-bottom: 0.8rem; }
  .spb--sm--xl {
    padding-bottom: 1.6rem; }
  .spb--md--xl {
    padding-bottom: 2.4rem; }
  .spb--lg--xl {
    padding-bottom: 3.2rem; }
  .spb--xl--xl {
    padding-bottom: 4.8rem; }
  .spb--xxl--xl {
    padding-bottom: 6.4rem; }
  .spb--xxxl--xl {
    padding-bottom: 8rem; } }

@media (min-width: 1600px) {
  .spb--none--xxl {
    padding-bottom: 0; }
  .spb--xs--xxl {
    padding-bottom: 0.8rem; }
  .spb--sm--xxl {
    padding-bottom: 1.6rem; }
  .spb--md--xxl {
    padding-bottom: 2.4rem; }
  .spb--lg--xxl {
    padding-bottom: 3.2rem; }
  .spb--xl--xxl {
    padding-bottom: 4.8rem; }
  .spb--xxl--xxl {
    padding-bottom: 6.4rem; }
  .spb--xxxl--xxl {
    padding-bottom: 8rem; } }

.spl--none {
  padding-left: 0; }

.spl--xs {
  padding-left: 0.8rem; }

.spl--sm {
  padding-left: 1.6rem; }

.spl--md {
  padding-left: 2.4rem; }

.spl--lg {
  padding-left: 3.2rem; }

.spl--xl {
  padding-left: 4.8rem; }

.spl--xxl {
  padding-left: 6.4rem; }

.spl--xxxl {
  padding-left: 8rem; }

@media (min-width: 400px) {
  .spl--none--xs {
    padding-left: 0; }
  .spl--xs--xs {
    padding-left: 0.8rem; }
  .spl--sm--xs {
    padding-left: 1.6rem; }
  .spl--md--xs {
    padding-left: 2.4rem; }
  .spl--lg--xs {
    padding-left: 3.2rem; }
  .spl--xl--xs {
    padding-left: 4.8rem; }
  .spl--xxl--xs {
    padding-left: 6.4rem; }
  .spl--xxxl--xs {
    padding-left: 8rem; } }

@media (min-width: 640px) {
  .spl--none--sm {
    padding-left: 0; }
  .spl--xs--sm {
    padding-left: 0.8rem; }
  .spl--sm--sm {
    padding-left: 1.6rem; }
  .spl--md--sm {
    padding-left: 2.4rem; }
  .spl--lg--sm {
    padding-left: 3.2rem; }
  .spl--xl--sm {
    padding-left: 4.8rem; }
  .spl--xxl--sm {
    padding-left: 6.4rem; }
  .spl--xxxl--sm {
    padding-left: 8rem; } }

@media (min-width: 768px) {
  .spl--none--md {
    padding-left: 0; }
  .spl--xs--md {
    padding-left: 0.8rem; }
  .spl--sm--md {
    padding-left: 1.6rem; }
  .spl--md--md {
    padding-left: 2.4rem; }
  .spl--lg--md {
    padding-left: 3.2rem; }
  .spl--xl--md {
    padding-left: 4.8rem; }
  .spl--xxl--md {
    padding-left: 6.4rem; }
  .spl--xxxl--md {
    padding-left: 8rem; } }

@media (min-width: 1024px) {
  .spl--none--lg {
    padding-left: 0; }
  .spl--xs--lg {
    padding-left: 0.8rem; }
  .spl--sm--lg {
    padding-left: 1.6rem; }
  .spl--md--lg {
    padding-left: 2.4rem; }
  .spl--lg--lg {
    padding-left: 3.2rem; }
  .spl--xl--lg {
    padding-left: 4.8rem; }
  .spl--xxl--lg {
    padding-left: 6.4rem; }
  .spl--xxxl--lg {
    padding-left: 8rem; } }

@media (min-width: 1280px) {
  .spl--none--xl {
    padding-left: 0; }
  .spl--xs--xl {
    padding-left: 0.8rem; }
  .spl--sm--xl {
    padding-left: 1.6rem; }
  .spl--md--xl {
    padding-left: 2.4rem; }
  .spl--lg--xl {
    padding-left: 3.2rem; }
  .spl--xl--xl {
    padding-left: 4.8rem; }
  .spl--xxl--xl {
    padding-left: 6.4rem; }
  .spl--xxxl--xl {
    padding-left: 8rem; } }

@media (min-width: 1600px) {
  .spl--none--xxl {
    padding-left: 0; }
  .spl--xs--xxl {
    padding-left: 0.8rem; }
  .spl--sm--xxl {
    padding-left: 1.6rem; }
  .spl--md--xxl {
    padding-left: 2.4rem; }
  .spl--lg--xxl {
    padding-left: 3.2rem; }
  .spl--xl--xxl {
    padding-left: 4.8rem; }
  .spl--xxl--xxl {
    padding-left: 6.4rem; }
  .spl--xxxl--xxl {
    padding-left: 8rem; } }

.spr--none {
  padding-right: 0; }

.spr--xs {
  padding-right: 0.8rem; }

.spr--sm {
  padding-right: 1.6rem; }

.spr--md {
  padding-right: 2.4rem; }

.spr--lg {
  padding-right: 3.2rem; }

.spr--xl {
  padding-right: 4.8rem; }

.spr--xxl {
  padding-right: 6.4rem; }

.spr--xxxl {
  padding-right: 8rem; }

@media (min-width: 400px) {
  .spr--none--xs {
    padding-right: 0; }
  .spr--xs--xs {
    padding-right: 0.8rem; }
  .spr--sm--xs {
    padding-right: 1.6rem; }
  .spr--md--xs {
    padding-right: 2.4rem; }
  .spr--lg--xs {
    padding-right: 3.2rem; }
  .spr--xl--xs {
    padding-right: 4.8rem; }
  .spr--xxl--xs {
    padding-right: 6.4rem; }
  .spr--xxxl--xs {
    padding-right: 8rem; } }

@media (min-width: 640px) {
  .spr--none--sm {
    padding-right: 0; }
  .spr--xs--sm {
    padding-right: 0.8rem; }
  .spr--sm--sm {
    padding-right: 1.6rem; }
  .spr--md--sm {
    padding-right: 2.4rem; }
  .spr--lg--sm {
    padding-right: 3.2rem; }
  .spr--xl--sm {
    padding-right: 4.8rem; }
  .spr--xxl--sm {
    padding-right: 6.4rem; }
  .spr--xxxl--sm {
    padding-right: 8rem; } }

@media (min-width: 768px) {
  .spr--none--md {
    padding-right: 0; }
  .spr--xs--md {
    padding-right: 0.8rem; }
  .spr--sm--md {
    padding-right: 1.6rem; }
  .spr--md--md {
    padding-right: 2.4rem; }
  .spr--lg--md {
    padding-right: 3.2rem; }
  .spr--xl--md {
    padding-right: 4.8rem; }
  .spr--xxl--md {
    padding-right: 6.4rem; }
  .spr--xxxl--md {
    padding-right: 8rem; } }

@media (min-width: 1024px) {
  .spr--none--lg {
    padding-right: 0; }
  .spr--xs--lg {
    padding-right: 0.8rem; }
  .spr--sm--lg {
    padding-right: 1.6rem; }
  .spr--md--lg {
    padding-right: 2.4rem; }
  .spr--lg--lg {
    padding-right: 3.2rem; }
  .spr--xl--lg {
    padding-right: 4.8rem; }
  .spr--xxl--lg {
    padding-right: 6.4rem; }
  .spr--xxxl--lg {
    padding-right: 8rem; } }

@media (min-width: 1280px) {
  .spr--none--xl {
    padding-right: 0; }
  .spr--xs--xl {
    padding-right: 0.8rem; }
  .spr--sm--xl {
    padding-right: 1.6rem; }
  .spr--md--xl {
    padding-right: 2.4rem; }
  .spr--lg--xl {
    padding-right: 3.2rem; }
  .spr--xl--xl {
    padding-right: 4.8rem; }
  .spr--xxl--xl {
    padding-right: 6.4rem; }
  .spr--xxxl--xl {
    padding-right: 8rem; } }

@media (min-width: 1600px) {
  .spr--none--xxl {
    padding-right: 0; }
  .spr--xs--xxl {
    padding-right: 0.8rem; }
  .spr--sm--xxl {
    padding-right: 1.6rem; }
  .spr--md--xxl {
    padding-right: 2.4rem; }
  .spr--lg--xxl {
    padding-right: 3.2rem; }
  .spr--xl--xxl {
    padding-right: 4.8rem; }
  .spr--xxl--xxl {
    padding-right: 6.4rem; }
  .spr--xxxl--xxl {
    padding-right: 8rem; } }

.sp--none {
  padding: 0; }

.sp--xs {
  padding: 0.8rem; }

.sp--sm {
  padding: 1.6rem; }

.sp--md {
  padding: 2.4rem; }

.sp--lg {
  padding: 3.2rem; }

.sp--xl {
  padding: 4.8rem; }

.sp--xxl {
  padding: 6.4rem; }

.sp--xxxl {
  padding: 8rem; }

@media (min-width: 400px) {
  .sp--none--xs {
    padding: 0; }
  .sp--xs--xs {
    padding: 0.8rem; }
  .sp--sm--xs {
    padding: 1.6rem; }
  .sp--md--xs {
    padding: 2.4rem; }
  .sp--lg--xs {
    padding: 3.2rem; }
  .sp--xl--xs {
    padding: 4.8rem; }
  .sp--xxl--xs {
    padding: 6.4rem; }
  .sp--xxxl--xs {
    padding: 8rem; } }

@media (min-width: 640px) {
  .sp--none--sm {
    padding: 0; }
  .sp--xs--sm {
    padding: 0.8rem; }
  .sp--sm--sm {
    padding: 1.6rem; }
  .sp--md--sm {
    padding: 2.4rem; }
  .sp--lg--sm {
    padding: 3.2rem; }
  .sp--xl--sm {
    padding: 4.8rem; }
  .sp--xxl--sm {
    padding: 6.4rem; }
  .sp--xxxl--sm {
    padding: 8rem; } }

@media (min-width: 768px) {
  .sp--none--md {
    padding: 0; }
  .sp--xs--md {
    padding: 0.8rem; }
  .sp--sm--md {
    padding: 1.6rem; }
  .sp--md--md {
    padding: 2.4rem; }
  .sp--lg--md {
    padding: 3.2rem; }
  .sp--xl--md {
    padding: 4.8rem; }
  .sp--xxl--md {
    padding: 6.4rem; }
  .sp--xxxl--md {
    padding: 8rem; } }

@media (min-width: 1024px) {
  .sp--none--lg {
    padding: 0; }
  .sp--xs--lg {
    padding: 0.8rem; }
  .sp--sm--lg {
    padding: 1.6rem; }
  .sp--md--lg {
    padding: 2.4rem; }
  .sp--lg--lg {
    padding: 3.2rem; }
  .sp--xl--lg {
    padding: 4.8rem; }
  .sp--xxl--lg {
    padding: 6.4rem; }
  .sp--xxxl--lg {
    padding: 8rem; } }

@media (min-width: 1280px) {
  .sp--none--xl {
    padding: 0; }
  .sp--xs--xl {
    padding: 0.8rem; }
  .sp--sm--xl {
    padding: 1.6rem; }
  .sp--md--xl {
    padding: 2.4rem; }
  .sp--lg--xl {
    padding: 3.2rem; }
  .sp--xl--xl {
    padding: 4.8rem; }
  .sp--xxl--xl {
    padding: 6.4rem; }
  .sp--xxxl--xl {
    padding: 8rem; } }

@media (min-width: 1600px) {
  .sp--none--xxl {
    padding: 0; }
  .sp--xs--xxl {
    padding: 0.8rem; }
  .sp--sm--xxl {
    padding: 1.6rem; }
  .sp--md--xxl {
    padding: 2.4rem; }
  .sp--lg--xxl {
    padding: 3.2rem; }
  .sp--xl--xxl {
    padding: 4.8rem; }
  .sp--xxl--xxl {
    padding: 6.4rem; }
  .sp--xxxl--xxl {
    padding: 8rem; } }

.spx--none {
  padding-left: 0;
  padding-right: 0; }

.spx--xs {
  padding-left: 0.8rem;
  padding-right: 0.8rem; }

.spx--sm {
  padding-left: 1.6rem;
  padding-right: 1.6rem; }

.spx--md {
  padding-left: 2.4rem;
  padding-right: 2.4rem; }

.spx--lg {
  padding-left: 3.2rem;
  padding-right: 3.2rem; }

.spx--xl {
  padding-left: 4.8rem;
  padding-right: 4.8rem; }

.spx--xxl {
  padding-left: 6.4rem;
  padding-right: 6.4rem; }

.spx--xxxl {
  padding-left: 8rem;
  padding-right: 8rem; }

@media (min-width: 400px) {
  .spx--none--xs {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--xs {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--xs {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--xs {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--xs {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--xs {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--xs {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--xs {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 640px) {
  .spx--none--sm {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--sm {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--sm {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--sm {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--sm {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--sm {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--sm {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--sm {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 768px) {
  .spx--none--md {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--md {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--md {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--md {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--md {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--md {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--md {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--md {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 1024px) {
  .spx--none--lg {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--lg {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--lg {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--lg {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--lg {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--lg {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--lg {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--lg {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 1280px) {
  .spx--none--xl {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--xl {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--xl {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--xl {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--xl {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--xl {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--xl {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--xl {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 1600px) {
  .spx--none--xxl {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--xxl {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--xxl {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--xxl {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--xxl {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--xxl {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--xxl {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--xxl {
    padding-left: 8rem;
    padding-right: 8rem; } }

.spy--none {
  padding-top: 0;
  padding-bottom: 0; }

.spy--xs {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem; }

.spy--sm {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem; }

.spy--md {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem; }

.spy--lg {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem; }

.spy--xl {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem; }

.spy--xxl {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem; }

.spy--xxxl {
  padding-top: 8rem;
  padding-bottom: 8rem; }

@media (min-width: 400px) {
  .spy--none--xs {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--xs {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--xs {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--xs {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 640px) {
  .spy--none--sm {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--sm {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--sm {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--sm {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 768px) {
  .spy--none--md {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--md {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--md {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--md {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 1024px) {
  .spy--none--lg {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--lg {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--lg {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--lg {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 1280px) {
  .spy--none--xl {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--xl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--xl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--xl {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 1600px) {
  .spy--none--xxl {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--xxl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--xxl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

.button, .nav--blog a {
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5rem;
  padding: .8rem 1.6rem;
  background-color: transparent;
  font-family: "Prata", serif;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;
  color: #232346;
  transition: background-color .2s ease; }
  .button[disabled], .nav--blog a[disabled], .button.is-disabled, .nav--blog a.is-disabled {
    background-color: #ddd !important;
    border-color: #ddd !important;
    color: #fff !important;
    box-shadow: none !important; }
    .button[disabled]:hover, .nav--blog a[disabled]:hover, .button.is-disabled:hover, .nav--blog a.is-disabled:hover {
      background-color: #ddd !important;
      border-color: #ddd !important;
      color: #fff !important;
      box-shadow: none !important;
      cursor: default; }
  @media (min-width: 1024px) {
    .button, .nav--blog a {
      font-size: 1.6rem;
      padding: 1.6rem 2rem; } }
  .button:hover, .nav--blog a:hover {
    cursor: pointer; }
  .button--primary {
    background-color: #232346;
    color: #fff; }
    .button--primary:hover {
      background-color: #343468; }
  .button--secondary, .nav--blog a {
    background-color: #fff;
    border-color: #232346;
    color: #232346; }
    .is-active .button--secondary, .is-active .nav--blog a, .nav--blog .is-active a {
      background-color: #232346;
      color: #fff; }
    .button--secondary:hover, .nav--blog a:hover {
      text-decoration: underline; }
  .button--tertiary {
    position: relative;
    z-index: 0;
    background-color: #fff;
    color: #232346; }
    .button--tertiary:hover {
      background-color: #e6e6e6; }
  .button--quaternary {
    background-color: #fff;
    color: #232346; }
  .button--ghost {
    border-color: #ddd;
    background-color: #fff; }
    .button--ghost:hover, .blog:hover .button--ghost {
      background-color: #F6F7FC; }
  .button--ghost-inverted {
    border-color: #fff;
    color: #fff; }
    .button--ghost-inverted:hover {
      background-color: #fff;
      color: #232346; }
  .button--square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 4.8rem;
    padding: 0; }

.button__icon {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem; }
  .button__icon--left {
    margin-right: .8rem; }
  .button__icon--ghost {
    fill: #232346; }
  .button__icon--ghost-inverted {
    fill: #fff; }
    .button:hover .button__icon--ghost-inverted, .nav--blog a:hover .button__icon--ghost-inverted {
      fill: #232346; }

.form .umbraco-forms-field {
  margin-top: 2rem; }
  .form .umbraco-forms-field:first-child {
    margin-top: 0; }

.form .grid {
  margin: 0 -1rem; }

.form [class*="grid-item"] {
  padding: 0 1rem; }
  .form [class*="grid-item"] + [class*="grid-item"] {
    margin-top: 2rem; }
    @media (min-width: 1024px) {
      .form [class*="grid-item"] + [class*="grid-item"] {
        margin-top: 0; } }

.form__recaptcha {
  width: 100%; }

.form__field-wrapper {
  width: 100%;
  padding-top: 0.8rem; }

.form__header {
  margin-bottom: 3.2rem; }

.form__footer {
  margin-top: 3.2rem; }

.form__group + .form__group {
  margin-top: 4.8rem; }

.form__label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }
  .fileupload .form__label {
    flex: 0 0 auto;
    padding-right: 1.6rem;
    padding-bottom: 0; }
  .form__label.is-visible {
    display: block; }

.form__tooltip {
  display: block;
  font-size: 1.2rem;
  color: #bbb; }

.form__input {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ddd;
  outline: 0;
  background-color: transparent;
  padding: 1.6rem 0;
  border-radius: 0;
  font-family: "Marcellus", serif;
  font-size: 1.8rem; }
  .contact__form--inverse .form__input {
    border-color: rgba(255, 255, 255, 0.5);
    color: #fff; }
    .contact__form--inverse .form__input:focus {
      border-color: #fff; }
  .form__input--textarea {
    padding: 1.6rem 2rem;
    min-height: 15rem;
    border: 1px solid #ddd; }
    .contact__form--inverse .form__input--textarea {
      border-color: rgba(255, 255, 255, 0.5); }
      .contact__form--inverse .form__input--textarea:focus {
        border-color: #fff; }
  .form__input::placeholder {
    opacity: 1;
    font-weight: 500;
    color: #232346; }
    .contact__form--inverse .form__input::placeholder {
      color: rgba(255, 255, 255, 0.5); }

.toggle {
  display: inline-flex;
  align-items: flex-start; }
  .toggle:hover {
    cursor: pointer; }
  .toggle__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px; }
  .toggle__switch {
    flex: 0 0 auto;
    display: inline-flex;
    background-color: #ddd;
    border-radius: 0.5rem;
    position: relative;
    margin-right: 1.2rem; }
    .toggle__switch:after {
      content: "";
      width: 3.2rem;
      height: 3.2rem;
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background-color: #e66c40;
      border-radius: 0.5rem;
      transition: transform 0.2s ease, background-color 0.2s ease; }
    :checked + .toggle__switch:after {
      background-color: #13b67a;
      transform: translateX(3.2rem); }
  .toggle__switch-icon {
    width: 1.6rem;
    height: 1.6rem;
    fill: #fff;
    display: block;
    margin: 0.8rem;
    position: relative;
    z-index: 1; }
    .toggle__switch-icon--times {
      padding: 0.2rem; }
  .toggle__label {
    margin-top: 0.4rem; }

.card {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
  padding: 2.4rem; }
  @media (min-width: 1024px) {
    .card {
      padding: 3.6rem; } }
  .card__title {
    margin-bottom: 2rem; }

.card__header {
  display: flex; }

.card__indicator {
  margin-left: auto;
  padding-left: 1.6rem;
  font-weight: 500;
  color: #bbb; }

.paragraph {
  width: 100%;
  padding-top: 3.6rem; }
  .paragraph--columns .grid {
    gap: 3.6rem 0; }
  .paragraph--columns .paragraph {
    padding-top: 0;
    padding-bottom: 0; }
  .paragraph:last-child {
    padding-bottom: 3.6rem; }
  @media (min-width: 640px) {
    .paragraph.call-to-action:last-child {
      padding-bottom: 7.2rem; } }
  @media (min-width: 1024px) {
    .paragraph {
      padding-top: 6.4rem; }
      .paragraph:last-child {
        padding-bottom: 6.4rem; }
      .paragraph.call-to-action:last-child {
        padding-bottom: 12.8rem; }
      .paragraph--columns .grid {
        gap: 6.4rem 0; } }

.timeline-w-image,
.timeline-w-brands {
  position: relative; }
  .timeline-w-image.is-last-timeline-item:after,
  .timeline-w-brands.is-last-timeline-item:after {
    content: none; }
  @media (min-width: 640px) {
    .timeline-w-image--left .grid,
    .timeline-w-brands--left .grid {
      flex-direction: row-reverse; } }
  .timeline-w-image .grid,
  .timeline-w-brands .grid {
    align-items: center; }
  .timeline-w-image [class*="grid-item"]:last-child,
  .timeline-w-brands [class*="grid-item"]:last-child {
    margin-top: 3.6rem; }
    @media (min-width: 640px) {
      .timeline-w-image [class*="grid-item"]:last-child,
      .timeline-w-brands [class*="grid-item"]:last-child {
        margin-top: 0; } }
  .timeline-w-image__title,
  .timeline-w-brands__title {
    font-family: "Poppins", sans-serif;
    color: #232346; }
    .timeline-w-image__title strong,
    .timeline-w-brands__title strong {
      color: #168C7F; }

.timeline-w-image__image {
  display: block;
  max-width: 100%;
  margin: 0 auto; }

.timeline-w-brands__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -2rem; }
  .timeline-w-brands__list li {
    padding: 1rem 2rem; }

.timeline-stop {
  position: relative; }
  .timeline-stop__body {
    max-width: 48rem;
    margin: 0 auto;
    padding-top: 9.2rem; }
    @media (min-width: 640px) {
      .timeline-stop__body {
        text-align: center;
        padding-top: 12.8rem; } }
  .timeline-stop__icon {
    width: 6rem;
    height: 6rem;
    border-radius: 4rem;
    background-color: #ea9900;
    position: absolute;
    z-index: 1;
    top: 3.6rem;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 640px) {
      .timeline-stop__icon {
        width: 8rem;
        height: 8rem;
        left: 50%;
        transform: translateX(-50%); }
        .timeline-stop__icon img {
          width: 4rem;
          height: 4rem; } }
    .timeline-stop__icon img {
      flex: 0 0 auto;
      display: block;
      width: 3rem;
      height: 3rem;
      fill: #fff; }

.quote {
  position: relative;
  width: 100%; }
  .quote .grid {
    align-items: center; }
  .quote__quote {
    width: 4.4rem;
    height: 4.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    border-radius: 0.5rem;
    background-color: #ddd;
    font-weight: bold;
    color: #fff; }
  .quote__quote-icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #fff; }

.simple-quote__body {
  font-family: "Marcellus", serif;
  font-size: 2.4rem;
  line-height: 1.25;
  color: #232346; }
  @media (min-width: 1024px) {
    .simple-quote__body {
      font-size: 2.8rem; } }

@media (min-width: 640px) {
  .call-to-action {
    padding-top: 12.8rem;
    padding-bottom: 6.4rem; } }

.call-to-action__wrapper {
  position: relative; }

.call-to-action__body {
  background-color: #232346;
  color: #fff;
  padding: 3.6rem; }
  @media (min-width: 640px) {
    .call-to-action__body {
      padding: 4.8rem 50% 4.8rem 4.8rem; } }

.call-to-action__image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0; }
  .call-to-action__image:after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    background-color: #168C7F;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0; }
    @media (min-width: 640px) {
      .call-to-action__image:after {
        content: none; } }
  @media (min-width: 640px) {
    .call-to-action__image {
      position: absolute;
      top: -3.6rem;
      bottom: -3.6rem;
      right: 0;
      width: 50%; } }
  @media (min-width: 1024px) {
    .call-to-action__image {
      top: -6.4rem;
      bottom: -6.4rem; } }
  .call-to-action__image img {
    width: 50%;
    height: 100%;
    object-fit: contain; }
    @media (min-width: 640px) {
      .call-to-action__image img {
        width: 100%; } }

.text--intro {
  background-color: #F6F7FC;
  padding-bottom: 3.6rem; }
  @media (min-width: 1024px) {
    .text--intro {
      padding-bottom: 6.4rem; } }

.text--large {
  font-size: 1.8rem; }
  @media (min-width: 1024px) {
    .text--large {
      font-size: 2.4rem; } }
  .text--large .rtxt strong {
    color: #168C7F; }

.text__body--inverted {
  background-color: #575e71;
  color: #fff;
  padding: 3.6rem; }
  .text__body--inverted h1, .text__body--inverted .experts__body h2, .experts__body .text__body--inverted h2, .text__body--inverted .experts__body blockquote, .experts__body .text__body--inverted blockquote, .text__body--inverted .departments__body h2, .departments__body .text__body--inverted h2, .text__body--inverted .departments__body blockquote, .departments__body .text__body--inverted blockquote, .text__body--inverted .organigram__item .count, .organigram__item .text__body--inverted .count,
  .text__body--inverted h2,
  .text__body--inverted blockquote,
  .text__body--inverted h3,
  .text__body--inverted h4,
  .text__body--inverted h5,
  .text__body--inverted h6 {
    font-family: "Poppins", sans-serif; }

.text-w-image--left .grid {
  flex-direction: row-reverse; }

.text-w-image__image {
  display: block;
  width: 64rem;
  max-width: 100%;
  margin: 0 auto; }
  .text-w-image__image--rounded {
    width: 32rem;
    border-radius: 50%; }

.flow {
  padding: 1.6rem; }
  @media (min-width: 1024px) {
    .flow {
      min-height: 75vh; } }
  .flow__question {
    display: block;
    font-size: 1.6rem;
    margin-bottom: .8em; }
  .flow__answer {
    transition: opacity .25s ease; }
    .flow__answer.is-disabled {
      opacity: .5; }
    .flow__answer + .flow__answer {
      margin-top: .8rem; }
    .flow__answer input {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px; }
      .flow__answer input:checked + label {
        background-color: rgba(22, 140, 127, 0.075);
        border-color: #168C7F; }
    .flow__answer label {
      display: block;
      font-weight: 500;
      line-height: 1.33;
      border: 2px solid #ddd;
      padding: 1.2rem 1.4rem;
      border-radius: .5rem; }
      .flow__answer label:hover {
        cursor: pointer; }
  .flow__reply {
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    background-color: rgba(19, 182, 122, 0.075);
    padding: 1.6rem;
    border-radius: .4rem; }
  .flow__reply-icon {
    flex: 0 0 auto;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    fill: #13b67a;
    margin-top: .2rem;
    margin-right: 1.6rem; }

.flow__list-item {
  width: 100%;
  max-width: 72rem;
  margin: 1.6rem auto 0; }
  .flow__list-item:first-child {
    margin-top: 0; }
  .flow__list-item.is-hidden {
    display: none; }

.cookiebanner {
  position: fixed;
  z-index: 99;
  bottom: 24px;
  left: 24px;
  width: 640px;
  height: auto;
  max-width: calc(100% - 48px);
  clip: auto;
  clip-path: none;
  overflow: visible;
  white-space: normal; }
  .cookiebanner.is-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px; }
  .cookiebanner:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 50%);
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0; }

.cookiebanner__body {
  position: relative;
  z-index: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 1s ease, transform 1s ease; }
  .cookiebanner.is-hidden .cookiebanner__body {
    opacity: 0;
    transform: translate3d(0, 24px, 0); }
  .cookiebanner__body.is-hidden {
    display: none; }

.cookiebanner__options {
  margin-top: 16px; }

.cookiebanner__actions {
  margin-top: 24px; }
  .cookiebanner__actions ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none; }
  .cookiebanner__actions li + li {
    margin-left: 16px; }

.switch__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }
  .switch__input:checked + .switch__field .switch__symbol {
    background-color: #168C7F; }
    .switch__input:checked + .switch__field .switch__symbol:before {
      transform: translateX(20px); }

.switch__field {
  display: flex;
  align-items: center; }
  .switch__field:hover {
    cursor: pointer; }

.switch__symbol {
  position: relative;
  display: block;
  width: 48px;
  height: 28px;
  background-color: #eee;
  border-radius: 14px;
  margin-right: 16px;
  transition: background-color .2s ease; }
  .switch__symbol:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(51, 51, 51, 0.1);
    transition: transform .2s ease; }

.switch__label {
  font-weight: 500;
  line-height: 1.5; }
  .switch__label small {
    display: block;
    font-weight: normal;
    color: #999; }

.main {
  width: 100%; }

.container {
  display: block;
  width: 100%;
  max-width: 1640px;
  margin: 0 auto;
  padding: 0 24px; }
  @media (min-width: 1024px) {
    .container {
      padding: 0 24px; } }
  .container--large {
    max-width: 1200px; }
  .container--medium {
    max-width: 960px; }
  .container--small {
    max-width: 800px; }
  .container--extrasmall {
    max-width: 640px; }

.section {
  padding: 3.2rem 0; }
  @media (min-width: 640px) {
    .section {
      padding: 6.4rem 0; } }

.bg--marine {
  background-color: #232346;
  color: #fff; }

.pagetitle {
  text-align: center; }

.rtxt h1:last-child, .rtxt .experts__body h2:last-child, .experts__body .rtxt h2:last-child, .rtxt .experts__body blockquote:last-child, .experts__body .rtxt blockquote:last-child, .rtxt .departments__body h2:last-child, .departments__body .rtxt h2:last-child, .rtxt .departments__body blockquote:last-child, .departments__body .rtxt blockquote:last-child, .rtxt .organigram__item .count:last-child, .organigram__item .rtxt .count:last-child,
.rtxt h2:last-child,
.rtxt blockquote:last-child,
.rtxt h3:last-child,
.rtxt h4:last-child,
.rtxt h5:last-child,
.rtxt h6:last-child,
.rtxt p:last-child,
.rtxt ol:last-child,
.rtxt ul:last-child {
  margin-bottom: 0; }

.rtxt h1, .rtxt .experts__body h2, .experts__body .rtxt h2, .rtxt .experts__body blockquote, .experts__body .rtxt blockquote, .rtxt .departments__body h2, .departments__body .rtxt h2, .rtxt .departments__body blockquote, .departments__body .rtxt blockquote, .rtxt .organigram__item .count, .organigram__item .rtxt .count,
.rtxt h2,
.rtxt blockquote,
.rtxt h3,
.rtxt h4,
.rtxt h5,
.rtxt h6 {
  margin-bottom: 0.66em; }

.rtxt *:not(.subtitle) + h1, .rtxt .experts__body *:not(.subtitle) + h2, .experts__body .rtxt *:not(.subtitle) + h2, .rtxt .experts__body *:not(.subtitle) + blockquote, .experts__body .rtxt *:not(.subtitle) + blockquote, .rtxt .departments__body *:not(.subtitle) + h2, .departments__body .rtxt *:not(.subtitle) + h2, .rtxt .departments__body *:not(.subtitle) + blockquote, .departments__body .rtxt *:not(.subtitle) + blockquote, .rtxt .organigram__item *:not(.subtitle) + .count, .organigram__item .rtxt *:not(.subtitle) + .count,
.rtxt *:not(.subtitle) + h2,
.rtxt *:not(.subtitle) + blockquote,
.rtxt *:not(.subtitle) + h3,
.rtxt *:not(.subtitle) + h4,
.rtxt *:not(.subtitle) + h5,
.rtxt *:not(.subtitle) + h6 {
  margin-top: 1.2em; }

.rtxt p,
.rtxt ol,
.rtxt ul,
.rtxt table {
  margin-bottom: 1.2em; }

.rtxt ol,
.rtxt ul {
  padding-left: 1em; }

.rtxt ol {
  list-style: decimal; }

.rtxt ul {
  list-style: disc; }

.rtxt li {
  padding-left: 0.4rem; }
  .rtxt li + li {
    margin-top: 0.4rem; }

.rtxt a:not([class]) {
  text-decoration: underline; }

.rtxt table {
  border: 1px solid #ddd;
  border-collapse: collapse; }

.rtxt th,
.rtxt td {
  border: 1px solid #ddd;
  padding: 4px 8px;
  font-size: 75%; }

.address {
  font-style: normal; }
  .address h4 {
    margin-bottom: 0.8em; }
  .address li {
    display: flex;
    align-items: flex-start; }
    .address li + li {
      margin-top: 0.8rem; }
  .address__icon {
    position: relative;
    top: .6rem;
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #232346;
    margin-right: 0.8rem; }

.hero {
  width: 100%;
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas: "top" "bottom";
  margin-top: -96px; }
  .hero:before {
    content: "";
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    grid-area: main;
    background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)); }
    @media (min-width: 1024px) {
      .hero:before {
        grid-area: left; } }
  @media (min-width: 1024px) {
    .hero {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "left right";
      min-height: 90vh;
      margin-top: -144px; } }

.hero__body {
  grid-area: top;
  z-index: 2;
  padding: calc(24px + 96px) 0 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  @media (min-width: 1024px) {
    .hero__body {
      grid-area: left;
      padding: calc(48px + 144px) 0px 48px; } }

.hero__image {
  grid-area: bottom;
  display: block; }
  .hero__image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (min-width: 1024px) {
    .hero__image {
      grid-area: left / left / right / right;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0; } }

.experts {
  width: 100%;
  position: relative;
  z-index: 0;
  display: grid;
  grid-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: "top" "bottom"; }
  @media (min-width: 1024px) {
    .experts {
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "left right";
      grid-gap: 48px;
      padding-bottom: 4.8rem; } }

.experts__body {
  grid-area: top;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding: 3.2rem 0; }
  @media (min-width: 1024px) {
    .experts__body {
      grid-area: left;
      text-align: right;
      padding: 4.8rem 0; } }

.experts__slideshow {
  width: 100%;
  overflow: hidden;
  grid-area: bottom;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 2; }
  @media (min-width: 1024px) {
    .experts__slideshow {
      grid-area: right;
      padding-left: 0;
      padding-right: 0; } }

.experts__slideshow__slides {
  margin: 0 -12px; }
  @media (min-width: 1024px) {
    .experts__slideshow__slides {
      margin: 0 -24px; } }

.experts__slideshow__slide {
  padding: 0 12px; }
  @media (min-width: 1024px) {
    .experts__slideshow__slide {
      padding: 0 24px; } }

.experts__controls {
  padding-top: 1.6rem;
  display: flex; }
  @media (min-width: 1024px) {
    .experts__controls {
      padding-top: 2.4rem; } }
  .experts__controls__control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #232346; }
    .experts__controls__control + .experts__controls__control {
      margin-left: 8px; }
    .experts__controls__control__icon {
      width: 16px;
      height: 16px;
      fill: #232346; }

.expert__image {
  opacity: 0.5; }
  .slick-current .expert__image {
    opacity: 1; }

.expert__body {
  visibility: hidden;
  text-align: center; }
  .slick-current .expert__body {
    visibility: visible; }

.expert__name {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  line-height: 1.25;
  color: #168C7F;
  margin-bottom: 0 !important; }

.expert__props {
  display: grid; }
  .expert__props a {
    text-decoration: none !important; }

.testimonials {
  width: 100%; }
  .testimonials .subtitle {
    color: inherit; }
  .testimonials__slider {
    position: relative;
    padding-bottom: 6rem; }
  .testimonials__slider-slides {
    margin: 0 -1rem; }
  .testimonials__slider-slide {
    padding: 0 1rem;
    outline: 0; }
  .testimonials__slider-button {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 4.4rem;
    height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.2rem;
    outline: 0;
    border: 1px solid rgba(35, 35, 70, 0.15);
    background-color: transparent;
    transition: all 0.2s ease; }
    .bg--sun .testimonials__slider-button {
      border-color: rgba(255, 255, 255, 0.15); }
    .testimonials__slider-button.is-hidden {
      display: none; }
    .testimonials__slider-button:hover {
      background-color: #F6F7FC;
      border-color: #F6F7FC;
      cursor: pointer; }
      .testimonials__slider-button:hover .testimonials__slider-button-icon {
        fill: #168C7F; }
      .bg--sun .testimonials__slider-button:hover .testimonials__slider-button-icon {
        fill: #232346; }
    .testimonials__slider-button--next {
      right: 16px; }
    .testimonials__slider-button--prev {
      left: 16px; }
  .testimonials__slider-button-icon {
    flex: 0 0 auto;
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    fill: #232346;
    transition: all 0.2s ease; }
    .bg--sun .testimonials__slider-button-icon {
      fill: #fff; }

.testimonial {
  position: relative;
  margin-top: 2.2rem;
  margin-bottom: 1.6rem;
  padding: 2.4rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #232346;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1); }
  .testimonial__image {
    max-width: 50%;
    margin: 0 auto 2.4rem; }
    .testimonial__image img {
      border-radius: 50%; }
  .testimonial__quote {
    position: absolute;
    top: -2.2rem;
    left: 2.4rem;
    width: 4.4rem;
    height: 4.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    border-radius: 0.5rem;
    background-color: #232346;
    font-weight: bold;
    color: #fff; }
  .testimonial__quote-icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #fff; }
  .testimonial__score-label {
    font-size: 1.2rem; }
  .testimonial__score-number {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #13b67a; }
  .testimonial__score-stars {
    display: flex;
    align-items: center;
    margin-left: 0.8rem; }
  .testimonial__score-star-wrapper {
    position: relative; }
    .testimonial__score-star-wrapper + .testimonial__score-star-wrapper {
      margin-left: 0.4rem; }
  .testimonial__score-star {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    fill: #13b67a; }
  .testimonial__score-star-background {
    display: block;
    width: 2rem;
    height: 2rem;
    fill: #F6F7FC; }

@media (min-width: 1024px) {
  .section.latest-blogs {
    padding-top: 3.2rem; } }

.team {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: "top" "bottom";
  margin-top: -96px; }
  @media (min-width: 1024px) {
    .team {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "left right";
      min-height: 90vh;
      margin-top: -144px; } }

.team__overview {
  grid-area: top;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: calc(24px + 96px) 0 24px; }
  @media (min-width: 1024px) {
    .team__overview {
      grid-area: left;
      padding: calc(48px + 144px) 0 48px 0; } }

@media (min-width: 1024px) {
  .team__overview-wrapper {
    padding-right: calc(160px + 48px); } }

.team__detail {
  grid-area: bottom;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 0;
  background-color: #232346; }
  @media (min-width: 1024px) {
    .team__detail {
      grid-area: right;
      padding: calc(48px + 144px) 0 48px; } }

.team__title {
  margin-bottom: 3.2rem; }

.team__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 24px; }
  @media (min-width: 400px) {
    .team__grid {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr; } }
  @media (min-width: 640px) {
    .team__grid {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; } }
  @media (min-width: 768px) {
    .team__grid {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; } }
  @media (min-width: 1024px) {
    .team__grid {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }

.teammember {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 24px; }
  @media (min-width: 1024px) {
    .teammember {
      grid-template-rows: 1fr;
      grid-template-columns: auto 1fr;
      grid-template-areas: "left right";
      grid-gap: 48px;
      margin-left: -160px; } }

.teammember__image {
  width: 100%;
  max-width: 320px;
  margin: 0 auto; }

.teammember__body {
  align-self: center;
  text-align: center;
  color: #fff; }
  @media (min-width: 1024px) {
    .teammember__body {
      text-align: left; } }

.teammember__name {
  font-family: "Poppins", sans-serif;
  font-size: 2.8rem;
  line-height: 1.25;
  margin-bottom: 0 !important; }

.teammember__props a {
  text-decoration: none !important; }

.portrait {
  overflow: hidden;
  mask: url(../images/portrait.svg); }

.departments {
  width: 100%;
  position: relative;
  z-index: 0;
  display: grid;
  grid-gap: 0px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: "top" "bottom"; }
  @media (min-width: 1024px) {
    .departments {
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "left right";
      grid-gap: 48px;
      padding-bottom: 4.8rem; } }

.departments__body {
  grid-area: top;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  padding: 3.2rem 0; }
  @media (min-width: 1024px) {
    .departments__body {
      grid-area: left;
      text-align: right;
      padding: 4.8rem 0; } }

.departments__slideshow {
  width: 100%;
  overflow: hidden;
  grid-area: bottom;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 2; }
  @media (min-width: 1024px) {
    .departments__slideshow {
      grid-area: right;
      padding-left: 0;
      padding-right: 0; } }

.departments__slideshow__slides {
  margin: 0 -12px; }
  @media (min-width: 1024px) {
    .departments__slideshow__slides {
      margin: 0 -24px; } }

.departments__slideshow__slide {
  padding: 0 12px; }
  @media (min-width: 1024px) {
    .departments__slideshow__slide {
      padding: 0 24px; } }

.departments__controls {
  padding-top: 1.6rem;
  display: flex; }
  @media (min-width: 1024px) {
    .departments__controls {
      padding-top: 2.4rem; } }
  .departments__controls__control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #232346; }
    .departments__controls__control + .departments__controls__control {
      margin-left: 8px; }
    .departments__controls__control__icon {
      width: 16px;
      height: 16px;
      fill: #232346; }

.department__image {
  opacity: 0.5; }
  .slick-current .department__image {
    opacity: 1; }

.department__body {
  visibility: hidden;
  text-align: center; }
  .slick-current .department__body {
    visibility: visible; }

.department__name {
  font-family: "Poppins", sans-serif;
  font-size: 2.2rem;
  line-height: 1.25;
  color: #168C7F;
  margin-bottom: 0 !important; }

.teammembers__grid--new {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 4.8rem 3.2rem; }

.teammember__body--new {
  display: grid;
  gap: .8rem; }

.organigram {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, auto); }

.organigram__item {
  font-family: "Poppins", sans-serif;
  line-height: 1.33;
  text-align: center;
  color: #232346;
  word-break: break-word; }
  .organigram__item .count {
    font-family: "Poppins", sans-serif;
    color: #168C7F; }
  .organigram__item h1, .organigram__item .experts__body h2, .experts__body .organigram__item h2, .organigram__item .experts__body blockquote, .experts__body .organigram__item blockquote, .organigram__item .departments__body h2, .departments__body .organigram__item h2, .organigram__item .departments__body blockquote, .departments__body .organigram__item blockquote, .organigram__item .count, .organigram__item h2, .organigram__item blockquote, .organigram__item h3, .organigram__item h4, .organigram__item h5, .organigram__item h6 {
    font-family: "Poppins", sans-serif; }
  .organigram__item--icon {
    width: 96px;
    height: 96px;
    grid-column: 2 / span 2;
    grid-row: 1;
    align-self: center;
    justify-self: center; }
    @media (min-width: 1024px) {
      .organigram__item--icon {
        width: 120px;
        height: 120px; } }
  .organigram__item--one {
    grid-column: 2 / span 2;
    grid-row: 3 / span 1; }
  .organigram__item--two {
    grid-column: 1 / span 2;
    grid-row: 7 / span 1; }
  .organigram__item--three {
    grid-column: 3 / span 2;
    grid-row: 7 / span 1; }

.organigram__line {
  display: block; }
  .organigram__line--icon {
    height: 40px;
    width: 0;
    border-right: 1px solid #ddd;
    grid-column: 2 / span 2;
    grid-row: 2 / span 1;
    justify-self: center; }
    @media (min-width: 1024px) {
      .organigram__line--icon {
        height: 80px; } }
  .organigram__line--one {
    height: 40px;
    width: 0;
    border-right: 1px solid #ddd;
    grid-column: 2 / span 2;
    grid-row: 4 / span 1;
    justify-self: center; }
    @media (min-width: 1024px) {
      .organigram__line--one {
        height: 80px; } }
  .organigram__line--two {
    height: 28px;
    width: 0;
    border-right: 1px solid #ddd;
    grid-column: 1 / span 2;
    grid-row: 6 / span 1;
    justify-self: center; }
    @media (min-width: 1024px) {
      .organigram__line--two {
        height: 68px; } }
  .organigram__line--three {
    height: 28px;
    width: 0;
    border-right: 1px solid #ddd;
    grid-column: 3 / span 2;
    grid-row: 6 / span 1;
    justify-self: center; }
    @media (min-width: 1024px) {
      .organigram__line--three {
        height: 68px; } }
  .organigram__line--horizontal {
    width: 100%;
    height: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    grid-column: 2 / span 2;
    grid-row: 5 / span 1; }

.phil__slideshow {
  position: relative; }
  .phil__slideshow__wrapper {
    width: 100%; }

.phil__controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 1024px) {
    .phil__controls {
      padding-top: 6.4rem; } }
  .phil__controls__control {
    position: absolute;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 1px solid #232346;
    background-color: #fff; }
    .phil__controls__control.is-hidden {
      display: none; }
    .phil__controls__control--next {
      left: 24px; }
    .phil__controls__control--prev {
      right: 24px; }
    @media (min-width: 1024px) {
      .phil__controls__control {
        width: 48px;
        height: 48px;
        border-radius: 24px; } }
    .phil__controls__control__icon {
      width: 12px;
      height: 12px;
      fill: #232346; }
      @media (min-width: 1024px) {
        .phil__controls__control__icon {
          width: 16px;
          height: 16px; } }

.phil__slogan {
  font-size: 2.4rem; }
  @media (min-width: 1024px) {
    .phil__slogan {
      font-size: 4rem; } }
  .phil__slogan__part.is-hidden {
    display: none; }

.phil__body {
  position: relative;
  padding-left: 6.4rem; }
  @media (min-width: 1024px) {
    .phil__body {
      padding-left: 9.6rem; } }
  .phil__body .phil__body__number {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1;
    color: #168C7F; }
    @media (min-width: 1024px) {
      .phil__body .phil__body__number {
        font-size: 4.8rem; } }

.phil__dots {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2.4rem; }
  .phil__dots ul {
    display: flex;
    align-items: center; }
  .phil__dots li {
    padding: 4px; }
    .phil__dots li.slick-active button {
      position: relative; }
      .phil__dots li.slick-active button:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #232346;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .phil__dots button {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: block;
    background-color: #fff;
    border: 2px solid #232346;
    overflow: hidden;
    text-indent: -999rem; }
    .phil__dots button:hover {
      cursor: pointer; }

@media (min-width: 640px) {
  .blogs__title {
    grid-column: 1 / span 2; } }

.blog + .blogs__title {
  margin-top: 6.4rem; }

.blogs__grid--recent {
  display: grid;
  grid-gap: 3.2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%; }
  @media (min-width: 640px) {
    .blogs__grid--recent {
      grid-template-columns: repeat(2, 1fr); } }

.blogs__line {
  width: 1px;
  height: 48px;
  margin: 0 auto;
  background-color: #232346; }
  @media (min-width: 1024px) {
    .blogs__line {
      height: 96px; } }

.blog {
  display: block;
  width: 100%; }
  .blog--small {
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 4px 16px rgba(35, 35, 70, 0.2); }
  .blog__title {
    display: block;
    margin-top: 0 !important; }
  .blog__author {
    display: block;
    margin-top: 4px;
    font-size: 90%;
    color: #168C7F; }
  .blog__cat {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;
    padding: 0 10px;
    height: 20px;
    border-radius: 10px;
    background-color: #168C7F;
    font-size: 75%;
    line-height: 1;
    color: #fff; }
  .blog__date {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #bbb;
    margin-bottom: 1.2rem !important; }
  .blog__date-icon {
    display: block;
    fill: #bbb;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem; }
  .blog__image {
    margin-bottom: 2.4rem; }
  .blog__body {
    display: block;
    width: 100%; }
  .blog__more {
    text-decoration: underline; }
  .blog__info {
    display: flex;
    line-height: 1.5; }
    .blog__info--horizontal {
      flex-direction: column;
      align-items: center; }
      @media (min-width: 768px) {
        .blog__info--horizontal {
          flex-direction: row;
          align-items: center; } }
    .blog__info--vertical {
      flex-direction: column;
      align-items: center; }
    .blog__info-image {
      display: block;
      border-radius: 50%; }
    .blog__info-image-wrapper {
      display: block;
      margin-bottom: 2.4rem;
      width: 33.33%;
      max-width: 120px;
      flex: 0 0 auto; }
      @media (min-width: 768px) {
        .blog__info-image-wrapper {
          margin-bottom: 0;
          margin-right: 2.4rem; } }
    .blog__info-desc {
      display: block; }

.nav--blog ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -4px; }

.nav--blog li {
  padding: 4px; }

.jobs__grid--recent {
  display: grid;
  grid-gap: 3.2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%; }

.job--small {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(35, 35, 70, 0.2); }
  @media (min-width: 640px) {
    .job--small {
      flex-direction: row;
      align-items: center; } }

.scan__pagetitle {
  margin-bottom: 6.4rem; }

.question + .question {
  margin-top: 1.6rem; }

.suggestion {
  margin-top: 3.2rem;
  padding-left: 7.6rem; }

.widget--timeline {
  max-width: 20rem;
  margin: 0 auto; }

.contact {
  padding: 6.4rem 0; }
  .contact__header {
    margin-bottom: 3.2rem; }
  .contact__footer {
    margin-top: 3.2rem; }
  .contact .address + .address {
    margin-top: 3.2rem; }

.map {
  width: 100%;
  height: 24rem;
  background-color: #ddd;
  position: relative; }
  @media (min-width: 1024px) {
    .map {
      height: 48rem; } }
  .map iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    border: 0; }

.header {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 24px 0; }

.wrapper--left {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px; }
  @media (min-width: 1024px) {
    .wrapper--left {
      padding-right: 0;
      max-width: 800px; } }

.wrapper--right {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px; }
  @media (min-width: 1024px) {
    .wrapper--right {
      padding-left: 0;
      max-width: 800px; } }

@media (min-width: 1024px) {
  .wrapper--small {
    max-width: 600px; } }

.logo-wrapper {
  display: block;
  margin-right: 48px; }
  @media (min-width: 1024px) {
    .logo-wrapper {
      margin-right: 96px; } }

.logo {
  display: inline-block;
  vertical-align: middle; }

.logo__image {
  height: 48px; }
  @media (min-width: 1024px) {
    .logo__image {
      height: 96px; } }

.nav {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0; }

.nav-button {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #168C7F; }
  @media (min-width: 1024px) {
    .nav-button {
      width: 96px;
      height: 96px; } }

.nav-button-icon {
  position: relative;
  width: 28px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff; }
  .is-active .nav-button-icon {
    background-color: transparent; }
  @media (min-width: 1024px) {
    .nav-button-icon {
      width: 56px; } }
  .nav-button-icon:before, .nav-button-icon:after {
    content: "";
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
    .is-active .nav-button-icon:before, .is-active .nav-button-icon:after {
      width: 28px; }
    @media (min-width: 1024px) {
      .nav-button-icon:before, .nav-button-icon:after {
        width: 40px; }
        .is-active .nav-button-icon:before, .is-active .nav-button-icon:after {
          width: 56px; } }
  .nav-button-icon:before {
    top: -8px; }
    .is-active .nav-button-icon:before {
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg); }
    @media (min-width: 1024px) {
      .nav-button-icon:before {
        top: -16px; } }
  .nav-button-icon:after {
    bottom: -8px; }
    .is-active .nav-button-icon:after {
      bottom: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg); }
    @media (min-width: 1024px) {
      .nav-button-icon:after {
        bottom: -16px; } }

.nav-button-label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }

.nav-panel {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  opacity: 0; }
  .nav-panel.is-active {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static;
    white-space: normal;
    width: auto;
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #168C7F;
    opacity: 1;
    overflow: auto;
    display: flex; }
  .nav-panel ul {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px; }
    @media (min-width: 1024px) {
      .nav-panel ul {
        padding: 96px; } }
  .nav-panel a:not([class]) {
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff; }
    @media (min-width: 1024px) {
      .nav-panel a:not([class]) {
        font-size: 4.8rem; } }
  .nav-panel .button, .nav-panel .nav--blog a, .nav--blog .nav-panel a {
    margin: 1.2rem 0 0;
    font-size: 2rem; }
    @media (min-width: 1024px) {
      .nav-panel .button, .nav-panel .nav--blog a, .nav--blog .nav-panel a {
        margin: 2.4rem 0 0;
        padding: 1.6rem 3rem;
        font-size: 3rem; } }

.footer {
  width: 100%;
  padding: 3.6rem 0;
  background-color: #fff; }
  .footer [class*="grid-item"] {
    padding-bottom: 3.2rem; }
  @media (min-width: 640px) {
    .footer {
      padding: 6.4rem 0; } }
  .footer__logo {
    margin-bottom: 3.2rem; }
  .footer__title {
    font-family: "Marcellus", serif;
    font-size: 3.2rem;
    margin-bottom: 1.2rem; }
    * + .footer__title {
      margin-top: 3.2rem; }
  .footer__divider {
    margin: 0 0 3.2rem;
    border: 0;
    border-top: 1px solid #ddd; }
    @media (min-width: 640px) {
      .footer__divider {
        margin: 3.2rem 0 6.4rem; } }
  .footer__disclaimer {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.5;
    opacity: .66; }

.nav--footer li + li {
  margin-top: 0.8rem; }

.nav--social ul {
  display: flex;
  align-items: center; }

.nav--social li + li {
  margin-left: 1.2rem; }

.nav--social .social__icon {
  fill: #232346; }

.social__icon {
  width: 2rem;
  height: 2rem; }

.do-the-test {
  width: 100%; }
  .do-the-test .grid {
    flex-direction: column-reverse; }
    @media (min-width: 640px) {
      .do-the-test .grid {
        flex-direction: row; } }
  .do-the-test__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%; }
    .do-the-test__image img {
      display: block;
      margin-bottom: -3.2rem;
      max-width: 40rem; }
      @media (min-width: 640px) {
        .do-the-test__image img {
          margin-bottom: -6.4rem; } }
  .do-the-test__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    max-width: 52rem; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
