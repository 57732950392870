.do-the-test {
    width: 100%;

    .grid {
        flex-direction: column-reverse;

        @include media-query(sm) {
            flex-direction: row;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;

        img {
            display: block;
            margin-bottom: -3.2rem;
            max-width: 40rem;

            @include media-query(sm) {
                margin-bottom: -6.4rem;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        max-width: 52rem;
    }
}
