.blogs {
    &__title {
        @include media-query(sm) {
            grid-column: 1 / span 2;
        }

        .blog + & {
            margin-top: 6.4rem;
        }
    }

    &__grid {
        &--recent {
            display: grid;
            grid-gap: 3.2rem;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            width: 100%;

            @include media-query(sm) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

.blogs__line {
    width: 1px;
    height: 48px;
    margin: 0 auto;
    background-color: $color_marine;

    @include media-query(lg) {
        height: 96px;
    }
}

.blog {
    display: block;
    width: 100%;

    &:hover {
        .button--ghost {
            @extend .button--ghost:hover;
        }
    }

    &--small {
        display: flex;
        flex-direction: column;
        padding: 2.4rem;
        border-radius: 2px;
        background-color: #fff;
        box-shadow: 0 4px 16px rgba($color_marine, 0.2);

        // & + .recent-blog--small {
        //     margin-top: 4rem;
        // }
    }

    &__title {
        display: block;
        margin-top: 0 !important;
    }

    &__author {
        display: block;
        margin-top: 4px;
        font-size: 90%;
        color: $color_teal;
    }

    &__cat {
        display: inline-flex;
        align-items: center;
        margin-top: 8px;
        padding: 0 10px;
        height: 20px;
        border-radius: 10px;
        background-color: $color_teal;
        font-size: 75%;
        line-height: 1;
        color: #fff;
    }

    &__date {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $color_mouse;
        margin-bottom: 1.2rem !important;
    }

    &__date-icon {
        display: block;
        fill: $color_mouse;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
    }

    &__image {
        margin-bottom: 2.4rem;
    }

    &__body {
        display: block;
        width: 100%;
    }

    &__more {
        text-decoration: underline;
    }

    &__info {
        display: flex;
        line-height: 1.5;

        &--horizontal {
            flex-direction: column;
            align-items: center;
            
            @include media-query(md) {
                flex-direction: row;
                align-items: center;
            }
        }

        &--vertical {
            flex-direction: column;
            align-items: center;
        }

        &-image {
            display: block;
            border-radius: 50%;
        }

        &-image-wrapper {
            display: block;
            margin-bottom: 2.4rem;
            width: 33.33%;
            max-width: 120px;
            flex: 0 0 auto;

            @include media-query(md) {
                margin-bottom: 0;
                margin-right: 2.4rem;
            }
        }

        &-desc {
            display: block;
        }
    }
}

.nav--blog {
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -4px;
    }

    li {
        padding: 4px;
    }

    a {
        @extend .button;
        @extend .button--secondary;
    }
}
