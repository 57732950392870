@mixin media-query($breakpoint, $query1: min, $query2: width) {
    $query: map-get($grid-breakpoints, $breakpoint);

    @if $query != null {
        @media (#{$query1}-#{$query2}: #{$query}) {
            @content;
        }
    } @else {
        @media (#{$query1}-#{$query2}: $breakpoint) {
            @content;
        }
    }
}

@mixin hidden() {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

@mixin visible() {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static;
    white-space: normal;
    width: auto;
}
