.team {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        "top"
        "bottom";
    margin-top: -96px;

    @include media-query(lg) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        min-height: 90vh;
        margin-top: -144px;
    }
}

.team__overview {
    grid-area: top;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: calc(24px + 96px) 0 24px;

    @include media-query(lg) {
        grid-area: left;
        padding: calc(48px + 144px) 0 48px 0;
    }
}

.team__overview-wrapper {
    @include media-query(lg) {
        padding-right: calc(160px + 48px);
    }
}

.team__detail {
    grid-area: bottom;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0;
    background-color: $color_marine;

    @include media-query(lg) {
        grid-area: right;
        padding: calc(48px + 144px) 0 48px;
    }
}

.team__title {
    margin-bottom: 3.2rem;
}

.team__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 24px;

    @include media-query(xs) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    @include media-query(sm) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @include media-query(md) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @include media-query(lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    // @include media-query(lg) {
    //     grid-template-columns: 1fr 1fr 1fr;
    // }
}

.teammember {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        "top"
        "bottom";
    grid-gap: 24px;

    @include media-query(lg) {
        grid-template-rows: 1fr;
        grid-template-columns: auto 1fr;
        grid-template-areas: "left right";
        grid-gap: 48px;
        margin-left: -160px;
    }
}

.teammember__image {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
}

.teammember__body {
    align-self: center;
    text-align: center;
    color: #fff;

    @include media-query(lg) {
        text-align: left;
    }
}

.teammember__name {
    font-family: "Poppins", sans-serif;
    font-size: 2.8rem;
    line-height: 1.25;
    margin-bottom: 0 !important;
}

.teammember__props {
    a {
        text-decoration: none !important;
    }
}

.portrait {
    overflow: hidden;
    mask: url(../images/portrait.svg);
}
