$grid-columns: 12;
$grid-gutter: 48px;
$grid-breakpoints: (
    xs: 400px,
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1600px,
);

$spacings: (
    none: 0,
    xs: .8rem,
    sm: 1.6rem,
    md: 2.4rem,
    lg: 3.2rem,
    xl: 4.8rem,
    xxl: 6.4rem,
    xxxl: 8rem,
);

$color_marine: #232346;
$color_ocean: #575e71;
$color_teal: #168C7F;
$color_azure: #f3f6Fc;
$color_leafs: #13b67a;
$color_orange: #ea9900;
$color_sun: #f2b920;
$color_lava: #e66c40;
$color_clouds: #F6F7FC;
$color_lightgrey: #ddd;
$color_mouse: #bbb;
