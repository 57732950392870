.button {
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5rem;
    padding: .8rem 1.6rem;
    background-color: transparent;
    font-family: "Prata", serif;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
    white-space: nowrap;
    color: $color_marine;
    transition: background-color .2s ease;

    &[disabled],
    &.is-disabled {
        background-color: $color_lightgrey !important;
        border-color: $color_lightgrey !important;
        color: #fff !important;
        box-shadow: none !important;

        &:hover {
            background-color: $color_lightgrey !important;
            border-color: $color_lightgrey !important;
            color: #fff !important;
            box-shadow: none !important;
            cursor: default;
        }
    }

    @include media-query(lg) {
        font-size: 1.6rem;
        padding: 1.6rem 2rem;
    }

    &:hover {
        cursor: pointer;
    }

    &--primary {
        background-color: $color_marine;
        color: #fff;

        &:hover {
            background-color: lighten($color_marine, 10%);
        }
    }

    &--secondary {
        background-color: #fff;
        border-color: $color_marine;
        color: $color_marine;

        .is-active & {
            background-color: $color_marine;
            color: #fff;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &--tertiary {
        position: relative;
        z-index: 0;
        background-color: #fff;
        color: $color_marine;

        &:hover {
            background-color: darken(#fff, 10%);
        }
    }

    &--quaternary {
        background-color: #fff;
        color: $color_marine;
    }

    &--ghost {
        border-color: $color_lightgrey;
        background-color: #fff;

        &:hover {
            background-color: $color_clouds;
        }
    }

    &--ghost-inverted {
        border-color: #fff;
        color: #fff;

        &:hover {
            background-color: #fff;
            color: $color_marine;
        }
    }

    &--square {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.8rem;
        height: 4.8rem;
        padding: 0;
    }
}

.button__icon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;

    &--left {
        margin-right: .8rem;
    }

    &--ghost {
        fill: $color_marine;
    }

    &--ghost-inverted {
        fill: #fff;

        .button:hover & {
            fill: $color_marine;
        }
    }
}