.hero {
    width: 100%;
    position: relative;
    z-index: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        "top"
        "bottom";
    margin-top: -96px;

    &:before {
        content: "";
        position: relative;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        grid-area: main;
        background-image: linear-gradient(to right, rgba(#fff, 1), rgba(#fff, 0));

        @include media-query(lg) {
            grid-area: left;
        }
    }

    @include media-query(lg) {
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        min-height: 90vh;
        margin-top: -144px;
    }
}

.hero__body {
    grid-area: top;
    z-index: 2;
    padding: calc(24px + 96px) 0 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-query(lg) {
        grid-area: left;
        padding: calc(48px + 144px) 0px 48px;
        //text-align: right;
    }
}

.hero__image {
    grid-area: bottom;
    display: block;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @include media-query(lg) {
        grid-area: left / left / right / right;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
}

.experts {
    width: 100%;
    position: relative;
    z-index: 0;
    display: grid;
    grid-gap: 0px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
        "top"
        "bottom";

    @include media-query(lg) {
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "left right";
        grid-gap: 48px;
        padding-bottom: 4.8rem;

        // &:after {
        //     content: '';
        //     display: block;
        //     width: 1px;
        //     height: 100%;
        //     background-color: $color_marine;
        //     position: absolute;
        //     top: 0;
        //     left: 50%;
        // }
    }
}

.experts__body {
    grid-area: top;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    padding: 3.2rem 0;

    h2 {
        @extend h1;
    }

    @include media-query(lg) {
        grid-area: left;
        text-align: right;
        padding: 4.8rem 0;
    }
}

.experts__slideshow {
    width: 100%;
    overflow: hidden;
    grid-area: bottom;
    padding-left: 24px;
    padding-right: 24px;
    z-index: 2;

    @include media-query(lg) {
        grid-area: right;
        padding-left: 0;
        padding-right: 0;
    }
}

.experts__slideshow__slides {
    margin: 0 -12px;

    @include media-query(lg) {
        margin: 0 -24px;
    }
}

.experts__slideshow__slide {
    padding: 0 12px;

    @include media-query(lg) {
        padding: 0 24px;
    }
}

.experts__controls {
    padding-top: 1.6rem;
    display: flex;

    @include media-query(lg) {
        padding-top: 2.4rem;
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        border: 1px solid $color_marine;

        & + & {
            margin-left: 8px;
        }

        &__icon {
            width: 16px;
            height: 16px;
            fill: $color_marine;
        }
    }
}

.expert__image {
    opacity: 0.5;

    .slick-current & {
        opacity: 1;
    }
}

.expert__body {
    visibility: hidden;
    text-align: center;

    .slick-current & {
        visibility: visible;
    }
}

.expert__name {
    font-family: "Poppins", sans-serif;
    font-size: 2.2rem;
    line-height: 1.25;
    color: $color_teal;
    margin-bottom: 0 !important;
}

.expert__props {
    display: grid;
    
    a {
        text-decoration: none !important;
    }
}

.testimonials {
    width: 100%;

    .subtitle {
        color: inherit;
    }

    &__slider {
        position: relative;
        padding-bottom: 6rem;
    }

    &__slider-slides {
        margin: 0 -1rem;
    }

    &__slider-slide {
        padding: 0 1rem;
        outline: 0;
    }

    &__slider-button {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 4.4rem;
        height: 4.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2.2rem;
        outline: 0;
        border: 1px solid rgba($color_marine, 0.15);
        background-color: transparent;
        transition: all 0.2s ease;

        .bg--sun & {
            border-color: rgba(#fff, 0.15);
        }

        &.is-hidden {
            display: none;
        }

        &:hover {
            background-color: $color_clouds;
            border-color: $color_clouds;
            cursor: pointer;

            .testimonials__slider-button-icon {
                fill: $color_teal;
            }

            .bg--sun & .testimonials__slider-button-icon {
                fill: $color_marine;
            }
        }

        &--next {
            right: 16px;
        }

        &--prev {
            left: 16px;
        }
    }

    &__slider-button-icon {
        flex: 0 0 auto;
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        fill: $color_marine;
        transition: all 0.2s ease;

        .bg--sun & {
            fill: #fff;
        }
    }
}

.testimonial {
    position: relative;
    margin-top: 2.2rem;
    margin-bottom: 1.6rem;
    padding: 2.4rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: $color_marine;
    box-shadow: 0 0.4rem 1.6rem rgba(#000, 0.1);

    &__image {
        max-width: 50%;
        margin: 0 auto 2.4rem;

        img {
            border-radius: 50%;
        }
    }

    &__quote {
        position: absolute;
        top: -2.2rem;
        left: 2.4rem;
        width: 4.4rem;
        height: 4.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        border-radius: 0.5rem;
        background-color: $color_marine;
        font-weight: bold;
        color: #fff;
    }

    &__quote-icon {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        fill: #fff;
    }

    &__score-label {
        font-size: 1.2rem;
    }

    &__score-number {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: $color_leafs;
    }

    &__score-stars {
        display: flex;
        align-items: center;
        margin-left: 0.8rem;
    }

    &__score-star-wrapper {
        position: relative;
        & + .testimonial__score-star-wrapper {
            margin-left: 0.4rem;
        }
    }

    &__score-star {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 2rem;
        height: 2rem;
        fill: $color_leafs;
    }

    &__score-star-background {
        display: block;
        width: 2rem;
        height: 2rem;
        fill: $color_clouds;
    }
}

.section.latest-blogs {
    @include media-query(lg) {
        padding-top: 3.2rem;
    }
}
