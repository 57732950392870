.organigram {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(7, auto);
}

.organigram__item {
    font-family: "Poppins", sans-serif;
    line-height: 1.33;
    text-align: center;
    color: $color_marine;
    word-break: break-word;

    .count {
        @extend h1;
        font-family: "Poppins", sans-serif;
        color: $color_teal;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: "Poppins", sans-serif;
    }

    &--icon {
        width: 96px;
        height: 96px;
        grid-column: 2 / span 2;
        grid-row: 1;
        align-self: center;
        justify-self: center;

        @include media-query(lg) {
            width: 120px;
            height: 120px;
        }
    }

    &--one {
        grid-column: 2 / span 2;
        grid-row: 3 / span 1;
    }

    &--two {
        grid-column: 1 / span 2;
        grid-row: 7 / span 1;
    }

    &--three {
        grid-column: 3 / span 2;
        grid-row: 7 / span 1;
    }
}

.organigram__line {
    display: block;

    &--icon {
        height: 40px;
        width: 0;
        border-right: 1px solid $color_lightgrey;
        grid-column: 2 / span 2;
        grid-row: 2 / span 1;
        justify-self: center;

        @include media-query(lg) {
            height: 80px;
        }
    }

    &--one {
        height: 40px;
        width: 0;
        border-right: 1px solid $color_lightgrey;
        grid-column: 2 / span 2;
        grid-row: 4 / span 1;
        justify-self: center;

        @include media-query(lg) {
            height: 80px;
        }
    }

    &--two {
        height: 28px;
        width: 0;
        border-right: 1px solid $color_lightgrey;
        grid-column: 1 / span 2;
        grid-row: 6 / span 1;
        justify-self: center;

        @include media-query(lg) {
            height: 68px;
        }
    }

    &--three {
        height: 28px;
        width: 0;
        border-right: 1px solid $color_lightgrey;
        grid-column: 3 / span 2;
        grid-row: 6 / span 1;
        justify-self: center;

        @include media-query(lg) {
            height: 68px;
        }
    }

    &--horizontal {
        width: 100%;
        height: 12px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-top: 1px solid $color_lightgrey;
        border-left: 1px solid $color_lightgrey;
        border-right: 1px solid $color_lightgrey;
        grid-column: 2 / span 2;
        grid-row: 5 / span 1;
    }
}
